import "core-js/stable";
//needs to be first

import React from "react";
import ReactDOM from "react-dom/client";
import { IntlProvider } from "react-intl";
import { AppRouter } from "./routes";
import * as serviceWorker from "./serviceWorker";
import "./styles/tailwind.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <IntlProvider locale="de" defaultLocale="de" onError={console.warn} onWarn={console.log}>
        <AppRouter />
        </IntlProvider>
    </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
