import { Link } from "react-router-dom";
import { companyName } from "../../constants/text";

export const Footer = () => {
    return (
        <footer className="bg-lightBackground dark:bg-darkBackground">
            <div className="container px-6 py-2 md:py-4 mx-auto flex items-center justify-between md:flex-row flex-col">
                <Link to="/">
                    <div className="text-primary">
                        <span className="text-xl">Nyxian Logo</span>
                    </div>
                </Link>

                <span className="text-gray-800 dark:text-gray-200">
                    &copy; {new Date().getFullYear()} {companyName}
                </span>
            </div>
        </footer>
    );
};
