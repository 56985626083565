import { Outlet } from "react-router-dom";
import { DarkModeButton } from "./components/Button/DarkModeButton";
import { Footer } from "./components/Footer";

function App() {
    return (
        <div className="flex flex-col justify-between h-screen dark:bg-gray-800/80">

            <Outlet />

            <Footer />

            <div className="fixed bottom-0 right-0">
                <DarkModeButton />
            </div>
        </div>
    );
}

export default App;
