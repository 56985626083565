type Props = {
    stat: string;
    title: string;
    source: string;
    subtitle?: string;
};

export type HeroStatProps = Props;

export const HeroStat = (props: Props) => {
    return <div className="container mx-auto p-16 lg:p-0">
        <div className="shadow-md bg-darkBackground dark:bg-lightBackground p-8 rounded-lg w-full h-full">
            <div className="float-left leading-[.75] font-extrabold text-center text-[5rem] lg:text-[10rem] 2xl:text-[20rem] mr-8">
                <span className="text-transparent bg-clip-text bg-gradient-to-tr from-primary to-secondary">
                    {props.stat}
                </span>
            </div>

            <div className="font-bold text-left text-4xl lg:text-6xl 2xl:text-7xl ">
                <span className="text-transparent bg-clip-text bg-accent">
                    {props.title}
                </span>
            </div>

            {props.subtitle && <h3 className="font-bold text-center text-3xl lg:text-5xl 2xl:text-7xl w-full">
                <span className="text-transparent bg-clip-text bg-accent">
                    {props.subtitle}
                </span>
            </h3>}
        </div>
    </div>
};