import { styles } from "../../../styles/constants";
import { IncreasedWorkerProductivityStat } from "./Stat/IncreasedWorkerProductivityStat";

export const HeroSection = () => {
    return (
        <section className="w-full min-h-screen bg-lightBackground dark:bg-darkBackground flex flex-col items-center justify-center text-primary" style={{
            backgroundImage: `url("${styles.heroBackgroundImage}")`
        }}>

            <IncreasedWorkerProductivityStat />

        </section>
    )
};