import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "../App";
import { FourZeroFourRoute } from "./error/404";
import { HomeRoute } from "./home";

export const AppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/:lang?/" element={<App />}>
                    <Route path="" element={<HomeRoute />} />
                </Route>

                <Route path="*" element={<FourZeroFourRoute />} />
            </Routes>
        </BrowserRouter>
    );
};
